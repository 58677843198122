.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.chatbot-linkbox{
  background-color: #1373e7;
  border-radius: 50%;
  width: 75px;
  height: 75px;
  margin-left: auto;
  margin-right: auto;
}
.react-chatbot-kit-chat-container {
}

.react-chatbot-kit-chat-inner-container {
}

.react-chatbot-kit-chat-header {

}

.react-chatbot-kit-chat-input-container {
}

.react-chatbot-kit-chat-message-container {
}

.react-chatbot-kit-chat-input {
}

.react-chatbot-kit-chat-input-form {
}

.react-chatbot-kit-chat-input::placeholder {
}

.react-chatbot-kit-chat-btn-send {
}

.react-chatbot-kit-chat-btn-send-icon {
}

.react-chatbot-kit-chat-bot-message-container {
  word-wrap: break-word;
  margin-right: 20px;
}

.react-chatbot-kit-chat-bot-avatar-container {
}

.react-chatbot-kit-chat-bot-avatar-icon {

}

.react-chatbot-kit-chat-bot-avatar-letter {
}

.react-chatbot-kit-chat-bot-message.span {
  /*word-wrap: break-word;*/
}

.react-chatbot-kit-chat-bot-message-arrow {
}

.react-chatbot-kit-chat-bot-loading-icon-container {
}

.chatbot-loader-container {
}

#chatbot-loader #chatbot-loader-dot1 {
}

#chatbot-loader #chatbot-loader-dot2 {
}

#chatbot-loader #chatbot-loader-dot3 {
}

.react-chatbot-kit-error {
}

.react-chatbot-kit-error-container {
}

.react-chatbot-kit-error-header {
}

.react-chatbot-kit-error-docs {
}


.react-chatbot-kit-user-avatar-icon.svg{
}
